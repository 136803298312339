import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PostList } from '~/pages/posts/PostList'
import { useCommunity } from '~/contexts/CommunityContext'
import {
  GetContentsDocument,
  GetContentsFullDocument,
  GetContentSortOrderDocument,
  GetContentsQuery,
  GetContentsQueryVariables,
  GetNewPostActivityDocument,
  SubscriptionInfo,
} from '~/api/generated/graphql'
import { useAuth } from '~/auth/Auth'
import { useBackgroundFetch } from '~/common/hooks/useBackgroundFetch'
import { NewActivityButton } from '~/common/NewActivityButton'
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import { updatePostsCache } from '~/utils'
import { usePageVisibility } from '~/contexts/PageVisibilityContext'

const CommunityContent = () => {
  const { communityId, newActivity, setNewActivity } = useCommunity()
  const { authUserId } = useAuth()
  const [postExpanded, setPostExpanded] = useState<boolean>(false)

  const {
    loading,
    previewData: data,
    error,
  } = useBackgroundFetch<GetContentsQuery, GetContentsQueryVariables>(
    GetContentsDocument,
    GetContentsFullDocument,
    {
      variables: { communityId: communityId ?? '', userId: authUserId ?? '' },
      skip: !communityId,
    },
    !postExpanded
  )

  const [getPostActivity] = useLazyQuery(GetNewPostActivityDocument)
  const client = useApolloClient()
  const showNewActivity = useCallback(async () => {
    const oldData = client.readQuery({
      query: GetContentSortOrderDocument,
      variables: { id: communityId ?? '' },
    })

    const newContentOrder = (JSON.parse(oldData?.community?.contentOrder) as string[]) ?? []
    newActivity.forEach(a => {
      if (a.isContent) {
        newContentOrder.unshift(a.objId)
      }
    })

    const newData = {
      ...oldData,
      community: {
        ...(oldData?.community ?? { communityId: null }),
        contentOrder: JSON.stringify(newContentOrder),
      },
    }

    client.writeQuery({
      query: GetContentSortOrderDocument,
      variables: { id: communityId ?? null },
      data: newData,
    })
    await updatePostsCache(
      newActivity,
      getPostActivity,
      client,
      communityId ?? '',
      authUserId ?? '',
      setNewActivity,
      GetContentsDocument
    )
  }, [authUserId, client, communityId, getPostActivity, newActivity, setNewActivity])

  const { pageIsVisible } = usePageVisibility()

  const onFetchData = useCallback(
    (contentsData: GetContentsQuery) => {
      const fetchedPosts = contentsData?.posts?.edges?.map(p => p?.node?.postId ?? '') ?? []
      const existingPosts = data?.posts?.edges?.map(p => p?.node?.postId ?? '') ?? []
      const newPosts = fetchedPosts.filter(p => !existingPosts?.includes(p))
      if (newPosts.length > 0) {
        setNewActivity?.(a => {
          const activity = [...a]
          activity.push(
            ...newPosts.map(newPostId => {
              return {
                objId: newPostId,
                isContent: true,
              } as SubscriptionInfo
            })
          )
          return activity
        })
      }
    },
    [data?.posts?.edges, setNewActivity]
  )

  const { data: postsOnVisibility } = useQuery(GetContentsDocument, {
    fetchPolicy: 'no-cache',
    variables: { communityId: communityId ?? '', userId: authUserId ?? '' },
    skip: !pageIsVisible,
  })

  useEffect(() => {
    if (postsOnVisibility) {
      onFetchData(postsOnVisibility)
    }
  }, [onFetchData, postsOnVisibility])

  const postIds = useMemo(() => {
    return (data?.posts?.edges?.map(e => e?.node?.postId)?.filter(Boolean) as string[]) || []
  }, [data])

  if (error) {
    return <div>Unable to find community</div>
  }

  return (
    <div className={'content-container'}>
      <div className={'top-row-controls'}>
        <h3 className={'comm-title'}>Content</h3>
        {/* Content tab does not need to care about new comments because that does not change the order of the content list */}
        <NewActivityButton
          newActivity={newActivity.filter(x => x.isContent) as SubscriptionInfo[]}
          loading={loading}
          showNewActivity={showNewActivity}
        />
      </div>
      <PostList
        showHasMore={false}
        postIds={postIds}
        fromContentPage={true}
        loading={loading}
        setExpanded={setPostExpanded}
        showEditor={true}
      />
    </div>
  )
}

export default CommunityContent
