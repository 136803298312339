import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PostList } from '~/pages/posts/PostList'
import { POSTS_PAGE_SIZE } from '~/contexts/PostsContext'
import { useCommunity } from '~/contexts/CommunityContext'
import {
  GetFollowedPostsDocument,
  GetFollowedPostsFullDocument,
  GetFollowedPostsQuery,
  GetFollowedPostsQueryVariables,
  GetNewPostActivityDocument,
  GetPostsDocument,
  GetPostsFullDocument,
  GetPostsQuery,
  GetPostsQueryVariables,
} from '~/api/generated/graphql'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import '@css/pages/community/CommunityPosts.scss'
import { useAuth } from '~/auth/Auth'
import { Nav } from 'react-bootstrap'
import { useBackgroundFetch } from '~/common/hooks/useBackgroundFetch'
import { useLoadObjUsers } from '~/common/hooks/useLoadObjUsers'
import { NewActivityButton } from '~/common/NewActivityButton'
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import { setFetchedActivity, updatePostsCache } from '~/utils'
import { usePageVisibility } from '~/contexts/PageVisibilityContext'

const CommunityPosts = () => {
  const { authUserId } = useAuth()
  const { communityId, newActivity, setNewActivity } = useCommunity()
  const { isCondensedPortrait: isMobile } = useWindowSize()
  const [postExpanded, setPostExpanded] = useState<boolean>(false)

  // these queries correspond to cache updates in PostUtils.ts/updateCommunityPostFollow.
  // update in both places.
  const {
    loading,
    previewData: data,
    error,
    loadMore: allLoadMore,
    fullData,
  } = useBackgroundFetch<GetPostsQuery, GetPostsQueryVariables>(
    GetPostsDocument,
    GetPostsFullDocument,
    {
      variables: { communityId: communityId ?? '', userId: authUserId ?? '', pageSize: POSTS_PAGE_SIZE },
      skip: !communityId || !authUserId,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
    !postExpanded
  )

  const {
    loading: followedLoading,
    previewData: followedData,
    error: followedError,
    loadMore: followedLoadMore,
    fullData: fullFollowedData,
  } = useBackgroundFetch<GetFollowedPostsQuery, GetFollowedPostsQueryVariables>(
    GetFollowedPostsDocument,
    GetFollowedPostsFullDocument,
    {
      variables: { communityId: communityId ?? '', pageSize: POSTS_PAGE_SIZE },
      skip: !(communityId || authUserId),
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
    !postExpanded
  )
  const { processedPostIds, loadingFirstPage: loadingFirstPostUsers } = useLoadObjUsers(
    data?.posts,

    'postId'
  )
  const { processedPostIds: processedFollowedPostIds, loadingFirstPage: loadingFirstFollowingUsers } = useLoadObjUsers(
    followedData?.posts,

    'postId'
  )
  useLoadObjUsers(fullData?.posts, 'postId')
  useLoadObjUsers(fullFollowedData?.posts, 'postId')

  const [tab, setTab] = useState<string>('All')

  const [getPostActivity] = useLazyQuery(GetNewPostActivityDocument)
  const client = useApolloClient()
  const showNewActivity = useCallback(async () => {
    await updatePostsCache(newActivity, getPostActivity, client, communityId ?? '', authUserId ?? '', setNewActivity)
    setTab('All')
  }, [authUserId, client, communityId, getPostActivity, newActivity, setNewActivity])

  const { pageIsVisible } = usePageVisibility()
  const { data: postsOnVisibility } = useQuery(GetPostsDocument, {
    fetchPolicy: 'no-cache',
    variables: { communityId: communityId ?? '', userId: authUserId ?? '', pageSize: POSTS_PAGE_SIZE },
    skip: !pageIsVisible,
  })

  useEffect(() => {
    if (postsOnVisibility) {
      setFetchedActivity(postsOnVisibility, setNewActivity, data)
    }
  }, [data, postsOnVisibility, setNewActivity])

  const followedPostIds = useMemo(() => {
    if ((followedData || !followedLoading) && communityId && !loadingFirstFollowingUsers) {
      return (
        (followedData?.posts?.edges
          ?.map(e => e?.node?.postId)
          ?.filter(id => Boolean(id) && processedFollowedPostIds?.has(id ?? '')) as string[]) || []
      )
    }
    return undefined
  }, [followedLoading, followedData, communityId, processedFollowedPostIds, loadingFirstFollowingUsers])

  const postIds = useMemo(() => {
    if (data && communityId && !loadingFirstPostUsers) {
      return (
        (data?.posts?.edges
          ?.map(e => e?.node?.postId)
          ?.filter(id => Boolean(id) && processedPostIds?.has(id ?? '')) as string[]) || []
      )
    }
    return undefined
  }, [data, communityId, processedPostIds, loadingFirstPostUsers])

  const pageInfo = data?.posts?.pageInfo
  const followedPageInfo = followedData?.posts?.pageInfo
  const hasMore = !!pageInfo?.hasNextPage
  const followedHasMore = !!followedPageInfo?.hasNextPage

  if (error || followedError) {
    return <div>Unable to find community</div>
  }

  return (
    <div className={`posts-container ${isMobile ? 'mobile' : ''}`}>
      <div className={'top-row-controls'}>
        <Nav className={`tab-controls ${isMobile ? 'mobile' : ''}`}>
          <Nav.Item className={`tab-options`}>
            <button role="all-posts" className={`${tab == 'All' ? ' selected' : ''}`} onClick={() => setTab('All')}>
              ALL POSTS
            </button>
          </Nav.Item>
          <Nav.Item className={`tab-options`}>
            <button
              role="followed-posts"
              className={`${tab == 'Followed' ? ' selected' : ''}`}
              onClick={() => setTab('Followed')}
            >
              FOLLOWED POSTS
            </button>
          </Nav.Item>
        </Nav>
        <NewActivityButton loading={loading} showNewActivity={showNewActivity} newActivity={newActivity} />
      </div>
      <PostList
        showHasMore={tab == 'All' ? hasMore : followedHasMore}
        postIds={tab == 'All' ? postIds : followedPostIds}
        onLoadMore={tab == 'All' ? allLoadMore : followedLoadMore}
        fromContentPage={false}
        isFollowed={tab == 'Followed'}
        loading={tab == 'All' ? loading || loadingFirstPostUsers : followedLoading || loadingFirstFollowingUsers}
        setExpanded={setPostExpanded}
      />
    </div>
  )
}

export default CommunityPosts
