import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import '@css/pages/company/CompanyHome.scss'
import { POSTS_PAGE_SIZE } from '~/contexts/PostsContext'
import { PostList } from '~/pages/posts/PostList'
import { Link } from 'react-router-dom'
import { useCommunity } from '~/contexts/CommunityContext'
import { EmployeeBadgeComponent } from '~/common/EmployeeBadgeComponent'
import { useNavigate } from 'react-router'
import { EventBadgeComponent } from '~/common/EventBadgeComponent'
import CommunityAboutDisplay from '~/pages/community/CommunityAboutDisplay'
import AccountPartnerJoin from '~/pages/company/AccountPartnerJoin'
import { useAuth } from '~/auth/Auth'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import {
  GetFollowedPostsDocument,
  GetFollowingPostsFullDocument,
  GetPostsDocument,
  GetPostsFullDocument,
  CommunityType,
  GetFollowedPostsQuery,
  GetFollowedPostsQueryVariables,
  GetPostsQuery,
  GetPostsQueryVariables,
  Membership,
  GetCommunityAboutDocument,
  GetCommunityLeadersDocument,
  GetCommunityUpcomingEventsDocument,
  GetFeaturedContentsDocument,
  GetNewPostActivityDocument,
} from '~/api/generated/graphql'
import { getStartOfCurrentDay, setFetchedActivity, sortMembers, updatePostsCache } from '~/utils'
import { elementClicked } from '~/common/EventLogger'
import { ContentBadgeComponent } from '~/common/ContentBadgeComponent'
import { Nav } from 'react-bootstrap'
import { useBackgroundFetch } from '~/common/hooks/useBackgroundFetch'
import { NewActivityButton } from '~/common/NewActivityButton'
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import { usePageVisibility } from '~/contexts/PageVisibilityContext'

const CompanyHome = () => {
  const { isCondensed } = useWindowSize()
  const { isVeevan, authUserId } = useAuth()
  const [tab, setTab] = useState<string>('All')
  const [postExpanded, setPostExpanded] = useState<boolean>(false)

  const { loading: commLoading, error, communityId, isVeeva, companyId, newActivity, setNewActivity } = useCommunity()

  const { loading: postsLoading, previewData: postsData } = useBackgroundFetch<GetPostsQuery, GetPostsQueryVariables>(
    GetPostsDocument,
    GetPostsFullDocument,
    {
      variables: { communityId: communityId ?? '', userId: authUserId ?? '', pageSize: POSTS_PAGE_SIZE },
      skip: !communityId,
      fetchPolicy: 'cache-and-network',
    },
    !postExpanded
  )

  const { loading: followedPostsLoading, previewData: followedPostsData } = useBackgroundFetch<
    GetFollowedPostsQuery,
    GetFollowedPostsQueryVariables
  >(
    GetFollowedPostsDocument,
    GetFollowingPostsFullDocument,
    {
      variables: { communityId: communityId ?? '', pageSize: POSTS_PAGE_SIZE },
      skip: !communityId,
      fetchPolicy: 'cache-and-network',
    },
    !postExpanded
  )

  const { data: eventData, loading: eventsLoading } = useQuery(GetCommunityUpcomingEventsDocument, {
    variables: { id: communityId || '', date: getStartOfCurrentDay() },
    skip: !communityId,
  })

  const { data: leadersData } = useQuery(GetCommunityLeadersDocument, {
    variables: { id: communityId || '' },
    skip: !communityId,
  })

  const { data: basicCommunityData, loading: basicCommunityLoading } = useQuery(GetCommunityAboutDocument, {
    variables: { id: communityId || '' },
    skip: !communityId,
  })

  const { pageIsVisible } = usePageVisibility()
  const { data: postsOnVisibility } = useQuery(GetPostsDocument, {
    fetchPolicy: 'no-cache',
    variables: { communityId: communityId ?? '', userId: authUserId ?? '', pageSize: POSTS_PAGE_SIZE },
    skip: !pageIsVisible,
  })

  useEffect(() => {
    if (postsOnVisibility) {
      setFetchedActivity(postsOnVisibility, setNewActivity, postsData)
    }
  }, [postsData, postsOnVisibility, setNewActivity])

  const [getPostActivity] = useLazyQuery(GetNewPostActivityDocument)
  const client = useApolloClient()
  const showNewActivity = useCallback(async () => {
    await updatePostsCache(newActivity, getPostActivity, client, communityId ?? '', authUserId ?? '', setNewActivity)
    setTab('All')
  }, [authUserId, client, communityId, getPostActivity, newActivity, setNewActivity])

  const postIds = useMemo(() => {
    if ((postsData || !postsLoading) && communityId) {
      return (postsData?.posts?.edges?.map(e => e?.node?.postId)?.filter(Boolean) as string[]) || []
    }
    return undefined
  }, [postsData, postsLoading, communityId])

  const followedPostIds = useMemo(() => {
    if ((followedPostsData || !followedPostsLoading) && communityId) {
      return (followedPostsData?.posts?.edges?.map(e => e?.node?.postId)?.filter(e => e) as string[]) || []
    }
  }, [followedPostsLoading, followedPostsData, communityId])

  const followedPostsToShow = useMemo(() => followedPostIds?.slice(0, 5) ?? [], [followedPostIds])

  const navigate = useNavigate()
  const postsToShow = useMemo(() => postIds?.slice(0, 5) ?? [], [postIds])
  const eventsToShow = useMemo(
    () => eventData?.community?.events?.edges?.map(e => e?.node).slice(0, 3),
    [eventData?.community]
  )
  const showMoreEventsButton = (eventData?.community?.events?.edges?.length ?? 0) > 3

  const { data: featuredContentData, loading: featuredContentLoading } = useQuery(GetFeaturedContentsDocument, {
    variables: { communityId: communityId ?? '' },
  })

  const content = useMemo(() => {
    const x = new Map()
    featuredContentData?.community?.posts?.edges?.forEach(e => x.set(e?.node?.postId, e?.node))
    return x
  }, [featuredContentData])

  const contentIds = useMemo(() => {
    return featuredContentData?.community?.posts?.edges?.map(e => e?.node?.postId ?? '') ?? []
  }, [featuredContentData])

  const orderedPosts = useMemo(() => {
    const contentOrder = featuredContentData?.community?.contentOrder
      ? (JSON.parse(featuredContentData?.community?.contentOrder) as string[])
      : []
    const x = contentOrder.filter(e => content.get(e))

    // If featured content is not included in the content order, append them to the end of the list of IDs
    if (x.length < contentIds.length) {
      const missing = contentIds.filter(i => x.indexOf(i) < 0)
      return x.concat(missing)
    }

    return x
  }, [featuredContentData, content, contentIds])

  const featuredToShow = useMemo(() => orderedPosts?.slice(0, 3), [orderedPosts])

  const accountPartners = useMemo(() => {
    if (leadersData?.community?.members) {
      const partners = leadersData?.community?.members?.edges
        ?.map(e => e?.node)
        .filter(n => !n?.user?.hidden)
        .filter(e => e) as Membership[]
      return sortMembers(partners)
    }
  }, [leadersData?.community?.members])

  const loading = commLoading || eventsLoading || featuredContentLoading || basicCommunityLoading

  const featuredContentClicked = (e: SyntheticEvent, postId: string) => {
    elementClicked(e, 'click-featured-content', { companyId: companyId, postId: postId })
    navigate(`../content/${postId}`)
  }

  const showLink =
    (tab == 'All' && (postIds?.length ?? 0) > 5) || (tab == 'Followed' && (followedPostIds?.length ?? 0) > 5)

  if (!communityId && loading) return <div>Loading ...</div>
  if (error) return <div>Unable to find company</div>
  if (!communityId) return <div>Unable to find community</div>

  const condensedClass = isCondensed ? 'condensed' : ''

  return (
    <>
      <div className={`home-container ${condensedClass}`}>
        <CommunityAboutDisplay
          about={basicCommunityData?.community?.about}
          isPublic={basicCommunityData?.community?.type === CommunityType.Public}
          companyId={companyId}
          communityName={basicCommunityData?.community?.name || ''}
          isCompany={true}
          expandAbout={!!basicCommunityData?.community?.expandAbout}
          loading={loading}
        />
        {!isVeeva && (
          <div className="home-section">
            <div className={'company-ap-container'}>
              <h3 className="section-title" tabIndex={0}>
                Account Partners{' '}
                <Link className={'more-about-partners'} to="../partners">
                  More about Account Partners {'>'}
                </Link>
              </h3>
              <AccountPartnerJoin />
            </div>
            {leadersData ? (
              <>
                {accountPartners?.length && accountPartners[0].leader ? (
                  <>
                    <div className={`account-partners-summary ${condensedClass}`}>
                      {accountPartners?.map(leader => (
                        <EmployeeBadgeComponent
                          key={leader.userId}
                          userId={leader.userId ?? null}
                          communityId={communityId}
                          clickEmployee={e => {
                            elementClicked(e, 'click-community-partner', {
                              communityId: communityId,
                              user_id: leader.userId,
                            })
                          }}
                          isLeaderBadge={true}
                          isCompanyHome={true}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <div>No Account Partners yet</div>
                )}
              </>
            ) : (
              <>Loading...</>
            )}
          </div>
        )}
        <div>
          {featuredToShow != undefined && featuredToShow?.length > 0 && (
            <div>
              <div>
                <div className={'flex-table no-highlight sectionTitle'}>
                  <div className="flex-table-row">
                    <div className="flex-col">
                      <div className="col align-bottom width-6">
                        <h3 tabIndex={0}>
                          Featured Content <Link to="../content">Show all Content {'>'}</Link>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`events-summary ${condensedClass}`}>
                  {featuredToShow?.map(postId => (
                    <ContentBadgeComponent
                      key={postId}
                      featuredContent={content.get(postId)}
                      clickEvent={event => featuredContentClicked(event, postId)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          {eventsToShow != undefined && eventsToShow?.length > 0 && (
            <div>
              <div>
                <div className={'flex-table no-highlight sectionTitle'}>
                  <div className="flex-table-row">
                    <div className="flex-col">
                      <div className="col align-bottom width-6">
                        <h3 tabIndex={0}>
                          Upcoming Events {showMoreEventsButton && <Link to="../events">Show all {'>'}</Link>}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`events-summary ${condensedClass}`}>
                  {eventsToShow?.map(e => (
                    <EventBadgeComponent
                      key={e?.eventId}
                      event={e}
                      clickEvent={() => navigate(`../events?e=${e?.eventId}`)}
                      canEdit={isVeevan ?? false}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="home-section">
          <div className={'top-row-controls'}>
            <Nav className={`tab-controls ${isCondensed ? 'mobile' : ''}`}>
              <Nav.Item className={`tab-options`}>
                <button role="all-posts" className={`${tab == 'All' ? ' selected' : ''}`} onClick={() => setTab('All')}>
                  ALL POSTS
                </button>
              </Nav.Item>
              <Nav.Item className={`tab-options`}>
                <button
                  role="followed-posts"
                  className={`${tab == 'Followed' ? ' selected' : ''}`}
                  onClick={() => setTab('Followed')}
                >
                  FOLLOWED POSTS
                </button>
              </Nav.Item>
            </Nav>
            <NewActivityButton newActivity={newActivity} loading={postsLoading} showNewActivity={showNewActivity} />
          </div>
          {postsLoading ? (
            'Loading Posts ...'
          ) : (
            <>
              <PostList
                showHasMore={false}
                postIds={tab == 'All' ? postsToShow : followedPostsToShow}
                fromContentPage={false}
                isFollowed={tab == 'Followed'}
                setExpanded={setPostExpanded}
              />
              {showLink && <Link to="../posts">View more Posts {'>'}</Link>}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default CompanyHome
