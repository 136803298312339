import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { EmployeeBadgeComponent } from '~/common/EmployeeBadgeComponent'
import { PostList } from '~/pages/posts/PostList'
import '@css/pages/community/CommunityAbout.scss'
import CommunityAboutDisplay from '~/pages/community/CommunityAboutDisplay'
import { useCommunity } from '~/contexts/CommunityContext'
import { POSTS_PAGE_SIZE } from '~/contexts/PostsContext'
import { useNavigate } from 'react-router'
import { EventBadgeComponent } from '~/common/EventBadgeComponent'
import { usePermissions } from '~/pages/posts/PostUtils'
import {
  CommunityType,
  GetCommunityAboutDocument,
  GetCommunityLeadersDocument,
  GetCommunityUpcomingEventsDocument,
  GetFeaturedContentsDocument,
  GetFollowedPostsDocument,
  GetFollowedPostsFullDocument,
  GetFollowedPostsQuery,
  GetFollowedPostsQueryVariables,
  GetNewPostActivityDocument,
  GetPostsDocument,
  GetPostsFullDocument,
  GetPostsQuery,
  GetPostsQueryVariables,
  GetUserCommunitiesDocument,
  Membership,
} from '~/api/generated/graphql'
import { useAuth } from '~/auth/Auth'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { getStartOfCurrentDay, setFetchedActivity, sortMembers, updatePostsCache } from '~/utils'
import { ContentBadgeComponent } from '~/common/ContentBadgeComponent'
import { elementClicked } from '~/common/EventLogger'
import { Nav } from 'react-bootstrap'
import { useBackgroundFetch } from '~/common/hooks/useBackgroundFetch'
import { useLoadObjUsers } from '~/common/hooks/useLoadObjUsers'
import { NewActivityButton } from '~/common/NewActivityButton'
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import { usePageVisibility } from '~/contexts/PageVisibilityContext'

const ABOUT_POSTS_MAX = 5

const CommunityAbout = () => {
  const { breakpoint, isCondensedPortrait: isMobile } = useWindowSize()
  const { hasLeaderPermissions } = usePermissions()
  const { loading: communityIdLoading, communityId, newActivity, setNewActivity } = useCommunity()
  const navigate = useNavigate()
  const { authUserId } = useAuth()
  const [tab, setTab] = useState<string>('All')
  const [postExpanded, setPostExpanded] = useState<boolean>(false)

  const { data: userCommunitiesData, loading: userCommunitiesLoading } = useQuery(GetUserCommunitiesDocument, {
    variables: { id: authUserId ?? '' },
    skip: !authUserId,
  })

  const { data: leadersData, loading: leadersLoading } = useQuery(GetCommunityLeadersDocument, {
    variables: { id: communityId ?? '' },
    skip: !communityId,
  })

  const leaders = useMemo(() => {
    return leadersData?.community?.members?.edges
      .map(e => e?.node)
      .filter(n => !n?.user?.hidden)
      .filter(e => e) as Membership[] | undefined
  }, [leadersData])

  const { data: basicCommunityData, loading: communityLoading } = useQuery(GetCommunityAboutDocument, {
    variables: { id: communityId || '' },
    skip: !communityId,
  })
  const [showAllLeaders, setShowAllLeaders] = useState<boolean>(false)

  const {
    loading: postsLoading,
    previewData: postsData,
    fullData,
  } = useBackgroundFetch<GetPostsQuery, GetPostsQueryVariables>(
    GetPostsDocument,
    GetPostsFullDocument,
    {
      variables: { communityId: communityId ?? '', userId: authUserId ?? '', pageSize: POSTS_PAGE_SIZE, cursor: null },
      skip: !communityId || !authUserId,
      fetchPolicy: 'cache-and-network',
    },
    !postExpanded
  )

  const {
    loading: followedPostsLoading,
    previewData: followedPostsData,
    fullData: fullFollowedData,
  } = useBackgroundFetch<GetFollowedPostsQuery, GetFollowedPostsQueryVariables>(
    GetFollowedPostsDocument,
    GetFollowedPostsFullDocument,
    {
      variables: { communityId: communityId ?? '', pageSize: POSTS_PAGE_SIZE, cursor: null },
      skip: !communityId,
      fetchPolicy: 'cache-and-network',
    },
    !postExpanded
  )

  const { data: communityEventsData, loading: eventsLoading } = useQuery(GetCommunityUpcomingEventsDocument, {
    variables: { id: communityId || '', date: getStartOfCurrentDay() },
    skip: !communityId,
  })
  const events = useMemo(
    () => communityEventsData?.community?.events?.edges?.map(e => e?.node) || [],
    [communityEventsData]
  )
  const { processedPostIds, loadingFirstPage: loadingFirstTopUsers } = useLoadObjUsers(
    postsData?.posts ?? null,
    'postId'
  )
  useLoadObjUsers(fullData?.posts, 'postId')
  const { processedPostIds: processedFollowedPostIds, loadingFirstPage: loadingFirstFollowedUsers } = useLoadObjUsers(
    followedPostsData?.posts ?? null,
    'postId'
  )
  useLoadObjUsers(fullFollowedData?.posts, 'postId')

  const [getPostActivity] = useLazyQuery(GetNewPostActivityDocument)
  const client = useApolloClient()
  const showNewActivity = useCallback(async () => {
    await updatePostsCache(newActivity, getPostActivity, client, communityId ?? '', authUserId ?? '', setNewActivity)
    setTab('All')
  }, [authUserId, client, communityId, getPostActivity, newActivity, setNewActivity])

  const { topPostIds, postIds } = useMemo(() => {
    let topPostIds: string[] | undefined
    let postIds: string[] | undefined
    if ((postsData || !postsLoading) && communityId && !loadingFirstTopUsers) {
      postIds =
        (postsData?.posts?.edges
          ?.map(e => e?.node?.postId)
          ?.filter(id => Boolean(id) && processedPostIds?.has(id ?? '')) as string[]) || []
      topPostIds = postIds?.slice(0, ABOUT_POSTS_MAX)
    }
    return { topPostIds, postIds }
  }, [postsData, postsLoading, communityId, loadingFirstTopUsers, processedPostIds])

  const { topFollowedPostIds, followedPostIds } = useMemo(() => {
    let topFollowedPostIds: string[] | undefined
    let followedPostIds: string[] | undefined
    if ((followedPostsData || !followedPostsLoading) && communityId && !loadingFirstFollowedUsers) {
      followedPostIds =
        (followedPostsData?.posts?.edges
          ?.map(e => e?.node?.postId)
          ?.filter(id => Boolean(id) && processedFollowedPostIds?.has(id ?? '')) as string[]) || []
      topFollowedPostIds = followedPostIds?.slice(0, ABOUT_POSTS_MAX)
    }
    return { topFollowedPostIds, followedPostIds }
  }, [followedPostsLoading, followedPostsData, communityId, processedFollowedPostIds, loadingFirstFollowedUsers])

  const leadersToShow = useMemo(() => {
    const sortedLeaders = sortMembers(leaders)
    return showAllLeaders ? sortedLeaders : sortedLeaders?.slice(0, 3)
  }, [showAllLeaders, leaders])

  const { data: featuredContentData, loading: featuredContentLoading } = useQuery(GetFeaturedContentsDocument, {
    variables: { communityId: communityId ?? '' },
    skip: !communityId,
  })

  const featuredContentClicked = (e: SyntheticEvent, postId: string) => {
    elementClicked(e, 'click-featured-content', { communityId: communityId, postId: postId })
    navigate(`../content/${postId}`)
  }

  const content = useMemo(() => {
    const x = new Map()
    featuredContentData?.community?.posts?.edges?.forEach(e => x.set(e?.node?.postId, e?.node))
    return x
  }, [featuredContentData])

  const contentIds = useMemo(() => {
    return featuredContentData?.community?.posts?.edges?.map(e => e?.node?.postId ?? '') ?? []
  }, [featuredContentData])

  const orderedPosts = useMemo(() => {
    const contentOrder = featuredContentData?.community?.contentOrder
      ? (JSON.parse(featuredContentData?.community?.contentOrder) as string[])
      : []
    const x = contentOrder.filter(e => content.get(e))

    // If featured content is not included in the content order, append them to the end of the list of IDs
    if (x.length < contentIds.length) {
      const missing = contentIds.filter(i => x.indexOf(i) < 0)
      return x.concat(missing)
    }

    return x
  }, [featuredContentData, content, contentIds])

  const featuredToShow = useMemo(() => orderedPosts?.slice(0, 3), [orderedPosts])
  const eventsToShow = useMemo(() => events?.slice(0, 3), [events])

  const showMoreLeadersButton = (leaders?.length ?? 0) > 3
  const showMoreEventsButton = (events?.length ?? 0) > 3
  const isCondensed = breakpoint <= SizeBreakpoint.md

  const handleShowAllLeaders = useCallback(() => {
    setShowAllLeaders(!showAllLeaders)
  }, [showAllLeaders])

  const loading =
    communityIdLoading ||
    communityLoading ||
    eventsLoading ||
    userCommunitiesLoading ||
    leadersLoading ||
    featuredContentLoading
  const hasData = communityId && basicCommunityData && communityEventsData && userCommunitiesData && leadersData

  const showLink =
    (tab == 'All' && (postIds?.length ?? 0) > 5) || (tab == 'Followed' && (followedPostIds?.length ?? 0) > 5)

  const { pageIsVisible } = usePageVisibility()
  const { data: postsOnVisibility } = useQuery(GetPostsDocument, {
    skip: !pageIsVisible,
    fetchPolicy: 'no-cache',
    variables: {
      communityId: communityId ?? '',
      userId: authUserId ?? '',
      pageSize: POSTS_PAGE_SIZE,
      cursor: null,
    },
  })

  useEffect(() => {
    if (postsOnVisibility) {
      setFetchedActivity(postsOnVisibility, setNewActivity, postsData)
    }
  }, [postsData, postsOnVisibility, setNewActivity])

  if (!hasData && loading) return <></>
  if (!communityId) return <div>Unable to find community</div>

  const handleClickLeader = (e: SyntheticEvent) => {
    elementClicked(e, 'click-community-leader', { communityId: communityId })
  }

  const condensedClass = isCondensed ? 'condensed' : ''
  return (
    <div className={`home-container ${condensedClass}`}>
      <CommunityAboutDisplay
        about={basicCommunityData?.community?.about}
        isPublic={basicCommunityData?.community?.type === CommunityType.Public}
        communityName={basicCommunityData?.community?.name || ''}
        isCompany={false}
        expandAbout={!!basicCommunityData?.community?.expandAbout}
        loading={loading}
      />
      <div className="flex-table no-highlight sectionTitle">
        <div className="flex-table-row">
          <div className="flex-col">
            <div className="col align-bottom width-6">
              <h3 tabIndex={0}>
                Leaders{' '}
                {showMoreLeadersButton && (
                  <div className="show-more" onClick={handleShowAllLeaders}>
                    {showAllLeaders ? 'Show less >' : 'Show all >'}
                  </div>
                )}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className={`community-members-summary ${condensedClass}`}>
        {leadersToShow?.map(leader => (
          <EmployeeBadgeComponent
            key={leader.userId}
            userId={leader.userId}
            isLeaderBadge={true}
            clickEmployee={handleClickLeader}
          />
        ))}
      </div>
      <div>
        {featuredToShow != undefined && featuredToShow?.length > 0 && (
          <div>
            <div>
              <div className={'flex-table no-highlight sectionTitle'}>
                <div className="flex-table-row">
                  <div className="flex-col">
                    <div className="col align-bottom width-6">
                      <h3 tabIndex={0}>
                        Featured Content <Link to="../content">Show all Content {'>'}</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`events-summary ${condensedClass}`}>
                {featuredToShow?.map(postId => (
                  <ContentBadgeComponent
                    key={postId}
                    featuredContent={content.get(postId)}
                    clickEvent={event => featuredContentClicked(event, postId)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        {eventsToShow != undefined && eventsToShow?.length > 0 && (
          <div>
            <div>
              <div className={'flex-table no-highlight sectionTitle'}>
                <div className="flex-table-row">
                  <div className="flex-col">
                    <div className="col align-bottom width-6">
                      <h3 tabIndex={0}>
                        Upcoming Events {showMoreEventsButton && <Link to="../events">Show all {'>'}</Link>}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`events-summary ${condensedClass}`}>
                {eventsToShow?.map(e => (
                  <EventBadgeComponent
                    key={e?.eventId}
                    event={e}
                    clickEvent={() => navigate(`../events?e=${e?.eventId}`)}
                    canEdit={hasLeaderPermissions}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {
        <>
          <div className={'top-row-controls'}>
            <Nav className={`tab-controls ${isMobile ? 'mobile' : ''}`}>
              <Nav.Item className={`tab-options`}>
                <button role="all-posts" className={`${tab == 'All' ? ' selected' : ''}`} onClick={() => setTab('All')}>
                  ALL POSTS
                </button>
              </Nav.Item>
              <Nav.Item className={`tab-options`}>
                <button
                  role="followed-posts"
                  className={`${tab == 'Followed' ? ' selected' : ''}`}
                  onClick={() => setTab('Followed')}
                >
                  FOLLOWED POSTS
                </button>
              </Nav.Item>
            </Nav>
            <NewActivityButton newActivity={newActivity} loading={postsLoading} showNewActivity={showNewActivity} />
          </div>
          <div className="communityPosts">
            <PostList
              showHasMore={false}
              postIds={tab == 'All' ? topPostIds : topFollowedPostIds}
              fromContentPage={false}
              isFollowed={tab == 'Followed'}
              setExpanded={setPostExpanded}
            />
          </div>
          {showLink && <Link to={'../posts'}>View more Posts {'>'}</Link>}
        </>
      }
    </div>
  )
}

export default CommunityAbout
